    <div class="container p-5">
        <div class="row">  
            <div class="col-12 p-3">
                <select [(ngModel)]="route" [ngModelOptions]="{standalone: true}" name="route" id="s1" class="form-control">
                    <option *ngFor="let r of routes" value="{{r}}">{{r}}</option>
                </select>
            </div>
        </div>
        <div class="row">  
            <div class="col-12 p-3">
                <select [(ngModel)]="method" [ngModelOptions]="{standalone: true}" name="method" id="s2" class="form-control">
                    <option *ngFor="let m of methods" value="{{m}}">{{m}}</option>
                </select>
            </div>
        </div>
        <div class="row">
            <div class="col-12 p-3">
                <label><b>Requests</b></label>
                <textarea [(ngModel)]="request" [ngModelOptions]="{standalone: true}" class="form-control" cols="30" rows="10"></textarea>
            </div>
        </div>
        <div class="row">
            <div class="col-12 p-3">
                <button type="button" class="btn btn-primary" (click)="doHttp()">
                    Test
                </button>
            </div>
        </div>
        <div class="row">
            <div class="col-8 p-3">
                <label><b>Response</b></label>
                <pre class="alert alert-info response">{{response}}</pre>
            </div>
        </div>
    </div>
