import { NbMenuItem } from "@nebular/theme";
import { IMenuResponse, IMenuResponseItems } from "../services/structure-http-interface/IMenu/IMenuResponse";
import { AbstractBiz } from "./decorator-base/AbstractBiz";

/**
 * 
 */
export class Menu extends AbstractBiz{
    private _items:IMenuResponseItems[] = [];
    private _interface:NbMenuItem[];
    constructor(decorator:IMenuResponse){
       super();
       this.decorator = decorator; 
       this._items = decorator.menu;
    }
    /**
     * 
     */
    public get items():IMenuResponseItems[]{
        return this._items;
    }
    /**
     * 
     */
    public get interface():NbMenuItem[]{
        return this._interface;
    }
    /**
     * 
     */
    public set interface(value:NbMenuItem[]){
        this._interface = value;
    }
}