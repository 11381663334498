import { ILoginResponse } from "../services/structure-http-interface/ILogin/ILoginResponse";
import { AbstractBiz } from "./decorator-base/AbstractBiz";

/**
 * 
 */
export class User extends AbstractBiz{
    private _username:string;
    private _password:string;
    /**
     * 
     */
    constructor(decorator:ILoginResponse){
        super();
        this.username = decorator.user.usuario;
        this.password = decorator.user.pass;
        this.decorator = decorator;
    }

    /**
     * 
     */
    get username():string{
        return this._username;
    }

    /**
     * 
     */
    set username(value:string){
        this._username = value;
    }

    /**
     * 
     */
    get password():string{
        return this._password;
    }

    /**
     * 
     */
    set password(value:string){
        this._password = value;
    }

    /**
     * 
     */
    get clientId():string | number{
       return (this.decorator as ILoginResponse).user.id_cliente
    }

    /**
     * 
     */
    get userId():string | number{
        return (this.decorator as ILoginResponse).user.id_usuario
     }

}