import { Component, OnInit } from '@angular/core';
import { HttpService } from '../services/http.service';

@Component({
  selector: 'ngx-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss']
})
export class TestComponent implements OnInit {

  public routes = [
    "login",
    "createcliente",
    "getcliente",
    "editarcliente",
    "pais",
    "region",
    "clientes",
    "inhabilitar_cliente",
    "createroles",
    "getroles",
    "editarRoles",
    "inhabilitar_roles",
    "createusuarios",
    "getusuario",
    "getusuarios",
    "editarusuario",
    "inhabilitar_usuario",
    "getmenu",
    "createpermisos",
    "getpermisos",
    "editarpermisos",
    "createferiados",
    "getferiados",
    "editarferiados",
    "getferiado",
    "getmonedas",
    "getipo_proyecto",
    "createproyectoI",
    "getproyectoI",
    "editarproyectoI",
    "inhabilitar_proyecto",
    "createproyectodos",
    "getproyectodos",
    "editarproyectodos",
    "deleteproyectodos",
    "createproyectotres",
    "getproyectotres",
    "editarproyectotres",
    "inhabilitar_issues",
    "createproyectocuatro",
    "getproyectocuatro",
    "editarproyectocuatro",
    "deleteferiado",
    "getusuariosclientes",
    "asignar_recurso_proyecto",
    "getferiados_proyecto",
    "getrecursos_asignados",
    "getrecurso_asig",
    "editarrecurso_asig",
    "deleterecursos_asig",
    "getproyectos",
    "calcular_fecha",
    "create_cronograma",
    "get_tareas",
    "editar_cronograma",
    "get_tipo_issues",
    "get_estados",
    "create_flujos_proyectos",
    "deleteflujo",
    "getflujos_proyectos",
    "get_flujo",
    "get_issues_padres",
    "get_estado",
    "create_issues",
    "create_archivos",
    "create_comentario",
    "edit_issues",
    "edit_comentario",
    "delete_archivos",
    "get_issues",
    "get_comentarios",
    "get_archivos",
    "delete_comentario",
    "create_horas",
    "edit_horas",
    "apro_rech_horas",
    "get_horas",
    "get_historial_issues",
    "create_iteracion",
    "get_issues_iteraciones",
    "mover_issues_iteraciones",
    "iniciar_iteracion",
    "get_corte",
    "get_iteraciones",
    "get_estados_siguientes",
    "calcular_duracion",
    "get_informes_issues",
    "get_cons_recurso",
    "get_estimadas_real",
    "get_replanificaciones",
    "reCalcular"
  ];

  public methods = [
    "POST",
    "GET"
  ];

  public route:string;
  public request:string;
  public response:string;
  public method:string;

  constructor(public httpService:HttpService) { }

  ngOnInit(): void {
  }


  getHeader(){
    return {
      "headers":{
        'Content-Type': 'application/json',
        "authorization":"ABCKDNLASNALKNDLAKNDALKDN" 
      }
      }
  }

  async doHttp(){
   try {
    let response :any;
    if(this.request != undefined && this.request.trim() != "")
      this.request = this.request.trim();
    switch(this.method){
      case "GET":
       response = await this.httpService.doGetHttp("/" + this.route,this.getHeader());
      break;
      case "POST":
        response = await this.httpService.doPostHttp("/" + this.route,this.getHeader(),(this.request == undefined) ? null : JSON.parse(this.request));
      break;
    }
    this.response = JSON.stringify(response);
   } catch (e) {
    this.response = e;
   }
  }

}
