import { IRequestBase } from "./IRequestBase";
import { IResponseBase } from "./IResponseBase";



/**
 * 
 */
export abstract class AbstractHttpService{

    protected static readonly URL_BASE = "https://api.pm-fenix.com/api";
    public request:IRequestBase = {
        headers:{}
    };

    /**
     * 
     * @param key 
     * @param value 
     */
    public putValueIntoBodyDefault(key:string,value:string|number|boolean){
      let body: any = this.getStorageBodyDefault();
      body[key] = value;
      this.saveInStorageBodyDefault(body);
    }

    /**
     * 
     * @returns 
     */
    private getStorageBodyDefault(){ 
      let storageBodyDefault:any = JSON.parse(localStorage.getItem("bodyDafaultValues"));
      return storageBodyDefault ?? {};
    }

    /**
     * 
     * @param body 
     */
    private saveInStorageBodyDefault(body:any){
      let storageBodyDefault:any = this.getStorageBodyDefault();
      for(let attr in body)
        storageBodyDefault[attr] = body[attr]
      localStorage.setItem("bodyDafaultValues",JSON.stringify(storageBodyDefault));
    }

    /**
     * 
     */
    private loadHeadersDefault():void{
        this.request.headers = {
        'Content-Type': 'application/json',
        "authorization":"ABCKDNLASNALKNDLAKNDALKDN"
        };
    }

    /**
     * 
     * @param defaultHeaders 
     * @returns 
     */
    protected getRequestBase(defaultHeaders:boolean = true):IRequestBase{
        if(defaultHeaders)
            this.loadHeadersDefault();
        return JSON.parse(JSON.stringify(this.request));
    };

     /**
    * 
    * @param url 
    * @param option 
    * @returns 
    */
    protected async doHttp<T>(url:string,option:any):Promise<T>{
     try{
       let response:any;
       let json = await fetch(url,option);
       response = await json.json();
       return response;
     }catch(e){
       console.error('Error:', e);
       throw e;
     }
   }

   /**
    * 
    * @param url 
    * @returns 
    */
   private buildUrl(url:string):string{
    return AbstractHttpService.URL_BASE + url;
   }

   /**
    * 
    * @param url 
    * @param option 
    * @returns 
    */
   public async doGetHttp<T>(url:string,option:any):Promise<T>{
    try{
      option.method = "GET";
      url = this.buildUrl(url);
      return this.doHttp(url,option);;
    }catch(e){
      console.error('Error:', e);
      throw e;
    }
  }

   /**
    * 
    * @param url 
    * @param option 
    * @returns 
    */
   public async doPostHttp<T>(url:string,option:any,body:any=null):Promise<T>{
    try{
      option.method = "POST";
      url = this.buildUrl(url);
      option.body = JSON.stringify({
        ...this.getStorageBodyDefault(),
        ...body
      });
      return this.doHttp(url,option);
    }catch(e){
      console.error('Error:', e);
      throw e;
    }
  }
}