import i18nEs from './es/es.json';
import i18nEn from './es/en.json';



/**
 * 
 */
export class Lang{ 
  static LANGUAGE = "es";
  static languages = [ 
    {
      "es":i18nEs
    },
    {
      "en":i18nEn
    }
  ];

 /**
  * 
  * @param key 
  * @returns 
  */  
 public static getI18N(key:string):string | null{  
   return Lang.languages.find(l => l[Lang.LANGUAGE] != null)[Lang.LANGUAGE][key] ?? key;
 }
}