import { Component } from '@angular/core';

@Component({
  selector: 'ngx-one-column-auth-layout',
  styleUrls: ['./one-column-auth.layout.scss'],
  template: `
    <nb-layout windowMode>
      <nb-layout-column>
        <nb-layout-header fixed>
          <ngx-header-login></ngx-header-login>
        </nb-layout-header>
        <ng-content select="router-outlet"></ng-content>
      </nb-layout-column>
    </nb-layout>
  `,
})
export class OneColumnAuthLayoutComponent {}
