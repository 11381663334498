import { Injectable } from '@angular/core';
import { ICurrencyRequest } from './structure-http-interface/ICurrency/ILanguageRequest';
import { HttpService } from './http.service';
import { ICurrencyItems, ICurrencyResponse } from './structure-http-interface/ICurrency/ILanguageResponse';
import { IProyectTypeRequest } from './structure-http-interface/IProyectType/IProyectTypeRequest';
import { IProyectTypeItems, IProyectTypeResponse } from './structure-http-interface/IProyectType/IProyectTypeResponse';
import { ICountryRequest } from './structure-http-interface/ICountry/ICountryRequest';
import { ICountryItems, ICountryResponse } from './structure-http-interface/ICountry/ICountryResponse';
import { IGetEstadosRequest } from './structure-http-interface/IEstados/IGetEstadosRequest';
import { IGetEstadosResponse, IGetEstadosItems } from './structure-http-interface/IEstados/IGetEstadosResponse';
import { IGetTipoIssuesRequest } from './structure-http-interface/ITipoIssues/IGetTipoIssuesRequest';
import { IGetTipoIssuesResponse, IGetTipoIssuesItems } from './structure-http-interface/ITipoIssues/IGetTipoIssuesResponse';


/**
 * 
 */
export enum STATIC_DATA_KEYS{
  CURRENCY = "monedas",
  PROYECT_TYPE = "tipo",
  COUNTRY = "pais",
  ESTADOS ="estados",
  TIPO_ISSUES = "tipo_issues"
}

@Injectable({
  providedIn: 'root'
})
export class StaticDataService {
  
  public static readonly COUNTRY_ID_DEFAULT:string = "0";
  public static readonly YEAR_ID_DEFAULT = "0";
  public static readonly COUNTRY_MAX_YEAR_DEFAULT:number = 101;

  constructor(private httpService:HttpService) { }

   /**
    * 
    */
   public async loadCurrency():Promise<void>{
    let request: ICurrencyRequest = this.httpService.getRequestProyect();
    let currencies: ICurrencyResponse = await  this.httpService.doPostHttp<ICurrencyResponse>("/getmonedas", request);
    localStorage.setItem(STATIC_DATA_KEYS.CURRENCY,JSON.stringify(currencies.monedas));
  }


  /**
   * 
   */
  public async loadProyectType():Promise<void>{
    let request: IProyectTypeRequest = this.httpService.getRequestProyect();
    let proyectTypeList: IProyectTypeResponse = await  this.httpService.doPostHttp<IProyectTypeResponse>("/getipo_proyecto", request);
    localStorage.setItem(STATIC_DATA_KEYS.PROYECT_TYPE,JSON.stringify(proyectTypeList.tipo));
  }


   /**
   * 
   */
   public async loadCountries():Promise<void>{
    let request: ICountryRequest = this.httpService.getRequestProyect();
    let countries: ICountryResponse = await  this.httpService.doGetHttp<ICountryResponse>("/pais", request);
    localStorage.setItem(STATIC_DATA_KEYS.COUNTRY,JSON.stringify(countries.pais));
  }
   /**
   * 
   */
   public async loadEstados():Promise<void>{
    let request: IGetEstadosRequest = this.httpService.getRequestestados();
    let estados: IGetEstadosResponse = await  this.httpService.doGetHttp<IGetEstadosResponse>("/get_estados", request);
    localStorage.setItem(STATIC_DATA_KEYS.ESTADOS,JSON.stringify(estados.estados));
  }
   /**
   * 
   */
   public async loadTipoIssues():Promise<void>{
    let request: IGetTipoIssuesRequest = this.httpService.getRequestTipoIssues();
    let tipo_issues: IGetTipoIssuesResponse = await  this.httpService.doGetHttp<IGetTipoIssuesResponse>("/get_tipo_issues", request);
    localStorage.setItem(STATIC_DATA_KEYS.TIPO_ISSUES,JSON.stringify(tipo_issues.tipo_issues));
  }
  /**
   * 
   */
  public get currencies():ICurrencyItems[] | null{
    return JSON.parse(localStorage.getItem(STATIC_DATA_KEYS.CURRENCY));
  }

  /**
   * 
   */
  public get proyectTypeList():IProyectTypeItems[] | null{
    return JSON.parse(localStorage.getItem(STATIC_DATA_KEYS.PROYECT_TYPE));
  }

  /**
   * 
   */
  public get countries():ICountryItems[] | null{
    return JSON.parse(localStorage.getItem(STATIC_DATA_KEYS.COUNTRY));
  }
  /**
   * 
   */
  public get estados():IGetEstadosItems[] | null{
    return JSON.parse(localStorage.getItem(STATIC_DATA_KEYS.ESTADOS));
  }
  /**
   * 
   */
  public get tipoIssues():IGetTipoIssuesItems[] | null{
    return JSON.parse(localStorage.getItem(STATIC_DATA_KEYS.TIPO_ISSUES));
  }

  /**
   * 
   * @param id 
   * @returns 
   */
  public findCountryById(id:string){ 
    return this.countries.find((country:ICountryItems)=>country.id.toString() === id);
  }
 

}
