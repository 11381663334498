import { Injectable } from '@angular/core';
import { IMenuResponse, IMenuResponseItems } from './structure-http-interface/IMenu/IMenuResponse';
import { IResponseBodyBase } from './structure-http-interface/base/IResponseBase';
import { ILoginResponse, IloginResponsePermissions, IloginResponseUser } from './structure-http-interface/ILogin/ILoginResponse';

@Injectable({
  providedIn: 'root'
})
export class DummyService {

  constructor() { }

  public async getDummyUser():Promise<ILoginResponse>{
    let user :IloginResponseUser = {
      usuario:"rudi",
      pass:"",
      activo:1,
      id_rol:1,
      id_cliente:1,
      id_usuario:1,
      id:1
    }
    let permissions:IloginResponsePermissions[] = [
      {
        id:1,
        id_permisos:1,
        id_menu:1,
        id_rol:1,
        crear:1,
        editar:1,
        consultar:1,
        inhabilitar:0
      },
      {
        id:2,
        id_permisos:2,
        id_menu:2,
        id_rol:1,
        crear:1,
        editar:1,
        consultar:1,
        inhabilitar:0
      },
      {
        id:3,
        id_permisos:3,
        id_menu:3,
        id_rol:1,
        crear:1,
        editar:1,
        consultar:1,
        inhabilitar:0
      },
      {
        id:4,
        id_permisos:4,
        id_menu:4,
        id_rol:1,
        crear:1,
        editar:1,
        consultar:1,
        inhabilitar:0
      },

    ]
    let response: ILoginResponse = {
      user:user,
      permisos:permissions,
      haveError:false
    }
    return response;
  }

  /**
   * 
   * @returns 
   */
  public async getDummyMenu():Promise<IMenuResponse>{
    let items :IMenuResponseItems[] = []; 
    items.push(
      {
        id:1,
        menu_descri:"Proyectos"
      },
      {
        id:2,
        menu_descri:"Cronograma"
      },
      {
        id:3,
        menu_descri:"Recursos"
      },
      {
        id:4,
        menu_descri:"Feriados"
      }
      );
    let response: IMenuResponse = {
      haveError:false,
      menu:items
    }
    return response;
  }
}
