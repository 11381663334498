import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SecurityService {

  private static readonly SALT_HASH_PASSWORD = 10;
  constructor() {

  }

  /**
   * 
   * @returns 
   */
  getSaltPassword(){
    return SecurityService.SALT_HASH_PASSWORD;
  }
}
