<div class="header-container">
  <div class="logo-container">
    <a class="logo" href="#"><h2 class="text-primary">
      <img  width="80%" src="../../../../assets/images/22.png" alt="">
    </h2></a>
  </div>
</div>

<div class="header-container">
  <nb-actions size="small">
    <nb-action class="user-action" *nbIsGranted="['view', 'user']" >
      <nb-user [nbContextMenu]="userMenu"
               [onlyPicture]="userPictureOnly"
               [name]="''"
               [picture]="'assets/images/user-icon.png'">
      </nb-user>
    </nb-action>
  </nb-actions>
</div>
