import { Component } from '@angular/core';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
    <span class="created-by">
      Copyright © 2024 MP Fenix. Todos los derechos reservados.<b><a  target="_blank"></a></b> 
    </span>
    <div class="socials">
      <a href="https://www.solucionesgt360.com" target="_blank"><img class="img-login" [src]="imagen1" width="35" height="35"alt=""></a>
      <a href="https://www.instagram.com/solucionesgt360?igsh=MW9kMzU4OTdzOGFqZg==" target="_blank"><img class="img-login" [src]="imagen2" width="35" height="35"alt=""></a>
      <a href="https://www.facebook.com/profile.php?id=61563602784048" target="_blank"><img class="img-login" [src]="imagen3" width="35" height="35"alt=""></a>
 
    </div>
  `,
})
export class FooterComponent {
    public imagen1:string="../../../../assets/images/www.png";
    public imagen2:string="../../../../assets/images/instagram.png";
    public imagen3:string="../../../../assets/images/facebook.png";
}
