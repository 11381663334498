import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { SecurityService } from './security.service';
import { IProyectRequest } from './structure-http-interface/IProyect/IProyectRequest';
import { IProyectResponse, IProyectResponseProyect } from './structure-http-interface/IProyect/IProyectoResponse';
import { Router } from '@angular/router';
import { ICreateProyectIRequest } from './structure-http-interface/ICreateProyectI/ICreateProyectIRequest';
import { ICreateProyectIResponse } from './structure-http-interface/ICreateProyectI/ICreateProyectIResponse';
import { ICreateProyectIVResponse } from './structure-http-interface/ICreateProyectIV/ICreateProyectIVResponse';
import { ICreateProyectIVRequest } from './structure-http-interface/ICreateProyectIV/ICreateProyectIVRequest';
import { IEditProyectIResponse } from './structure-http-interface/IEditProyectI/IEditProyectIResponse';
import { IEditProyectIRequest } from './structure-http-interface/IEditProyectI/IEditProyectIRequest';
import { ICreateProyectIIIRequest } from './structure-http-interface/ICreateProyectIII/ICreateProyectIIIRequest';
import { ICreateProyectIIIResponse } from './structure-http-interface/ICreateProyectIII/ICreateProyectIIIResponse';
import { ICreateUserRequest } from './structure-http-interface/IUsuarios/ICreateUserRequest';
import { ICreateUserResponse } from './structure-http-interface/IUsuarios/ICreateUserResponse';
import { Lang } from '../lang/Lang';
import { IEditProyectIVRequest } from './structure-http-interface/IEditProyectIV/IEditProyectIVRequest';
import { IEditProyectIVResponse } from './structure-http-interface/IEditProyectIV/IEditProyectIVResponse';
import {  IDeleteProyectIVResponse } from './structure-http-interface/IDeleteProyectIV/IDeleteProyectIVResponse';
import { IDeleteProyectIVRequest } from './structure-http-interface/IDeleteProyectIV/IDeleteProyectIVRequest';
import { IIteracionRequest } from './structure-http-interface/IIteracion/IIteracionResquest';
import { IIteracionResponse } from './structure-http-interface/IIteracion/IIteracionResponse';
import { IGetIssuesIteRequest } from './structure-http-interface/IIteracion/IGetIssuesIteRequest';
import { IGetIteracionesItems, IGetIteracionesResponse } from './structure-http-interface/IIteracion/IGetIteracionesResponse';
import { IGetIteracionesRequest } from './structure-http-interface/IIteracion/IGetIteracionesRequest';
import { IGetIssuesIteResponse } from './structure-http-interface/IIteracion/IGetIssuesIteResponse';
import { IMoverIssuesRequest } from './structure-http-interface/IIteracion/IMoverIssuesRequest';
import { IMoverissuesResponse } from './structure-http-interface/IIteracion/IMoverissuesResponse';
import { IIniciarIteRequest } from './structure-http-interface/IIteracion/IIniciarIteRequest';
import { IIniciarIteResponse } from './structure-http-interface/IIteracion/IIniciarIteResponse';
import { ICreateFeriadosAnioRequest } from './structure-http-interface/IProyect/ICreateFeriadosAnioRequest';
import { ICreateFeriadosAnioResponse } from './structure-http-interface/IProyect/ICreateFeriadosAnioResponse';
import { IGetFeriadosAnioRequest } from './structure-http-interface/IProyect/IGetFeriadosAnioRequest';
import { IGetFeriadosAnioResponse } from './structure-http-interface/IProyect/IGetFeriadosAnioResponse';
import { IDeleteFeriadoPaisRequest } from './structure-http-interface/IProyect/IDeleteFeriadoPaisRequest';
import { IDeleteFeriadoPaisResponse } from './structure-http-interface/IProyect/IDeleteFeriadoPaisResponse';
import { IAlcanceResponse, IAlcanceResponseProyect } from './structure-http-interface/IAlcance/IAlcanceResponse';
import { IFeriadosResponse, IFeriadosResponseProyect } from './structure-http-interface/IFeriados/IFeriadosResponse';
import { IAlcanceRequest } from './structure-http-interface/IAlcance/IAlcanceRequest';
import { IFeriadosRequest } from './structure-http-interface/IFeriados/IFeriadosRequest';
import { StaticDataService } from './static-data.service';
import { IDeleteProyectIIIRequest } from './structure-http-interface/IDeleteProyectIII/IDeleteProyectIIIRequest';
import { IDeleteProyectIIIResponse } from './structure-http-interface/IDeleteProyectIII/IDeleteProyectIIIResponse';
import { IEditProyectIIIRequest } from './structure-http-interface/IEditProyectIII/IEditProyectIIIRequest';
import { IEditProyectIIIResponse } from './structure-http-interface/IEditProyectIII/IEditProyectIIIResponse';
import { IGetCalendarioRequest } from './structure-http-interface/IProyect/IGetCalendarioRequest';
import { IGetCalendarioResponse } from './structure-http-interface/IProyect/IGetCalendarioResponse';
import { IAsignarRecursosRequest } from './structure-http-interface/IProyect/IAsignarRecursosRequest';
import { IAsignarRecursosResponse } from './structure-http-interface/IProyect/IAsignarRecursosResponse';
import { IGetRecursosAsigRequest } from './structure-http-interface/IProyect/IGetRecursosAsigRequest';
import { IGetRecursosAsigResponse } from './structure-http-interface/IProyect/IGetRecursosAsigResponse';
import { IDeleteRecurAsigResponse } from './structure-http-interface/IProyect/IDeleteRecurAsigResponse';
import { ICerrarProyectoResponse } from './structure-http-interface/IProyect/ICerrarProyectoResponse';
import { ICerrarProyectoRequest } from './structure-http-interface/IProyect/ICerrarProyectoRequest';


export type ProyectFull = {
   proyect:IProyectResponseProyect,
   alcances:IAlcanceResponse,
   feriados:IFeriadosResponse
};



@Injectable({
  providedIn: 'root'
})
export class ProyectService {

  proyectsFull:ProyectFull[]= [];
  proyects:IProyectResponseProyect[] = [];
  proyect:ICreateProyectIResponse;
  public iteracionList:IGetIteracionesResponse;
  public issuesiteList:IGetIssuesIteResponse;
  public feriadosxanio:IGetFeriadosAnioResponse;
  public calendario:IGetCalendarioResponse;
  public recursos_asig:IGetRecursosAsigResponse;
  public proy:number=0;
  public proyecto:number;

  constructor(private httpService:HttpService,public nav:Router, public staticDataService:StaticDataService) { 
    if(localStorage.getItem("proyects") !== null){
      this.proyectsFull = this.getProyectsFromMemory();
    }
  }

  actualizar(id_proyecto){
    this.proy = id_proyecto;
  }

    
  /**
   * 
   * @param username 
   * @param email 
   * @param password 
   */
  /** */
  public async loadProyects1(clientId:string | number, callbackErr:any,callbackSuccess:any):Promise<void>{
    let recurso:IProyectResponse;

    try{
      let request: IProyectRequest = this.httpService.getRequestProyect();
      let proyects = await  this.httpService.doPostHttp<IProyectResponse>("/getproyectos", request,{
        "id_cliente": clientId ?? null
      });
      this.proyects = proyects.proyecto;
      if(proyects.error){
        callbackErr(proyects.error);
      }else{
        callbackSuccess(proyects);
      }
    }catch(e){
      callbackErr("Ha ocurrido un error inesperado");
    }
  }

  /**
   * 
   */
  /** */
  public async getCalendario(id_proyecto:number,callbackErr:any,callbackSuccess:any):Promise<void>{
    let calendario:IGetCalendarioResponse;

    try{
      let request: IGetCalendarioRequest = this.httpService.getRequestCalendario();
      calendario = await  this.httpService.doPostHttp<IGetCalendarioResponse>("/getcalendario", request,{
        "id_proyecto": id_proyecto ?? null});
        this.calendario = calendario.calendario;
      if(calendario.error){
        callbackErr(calendario.error);
      }else{
        callbackSuccess();
      }
    }catch(e){
      callbackErr("Ha ocurrido un error inesperado");
    }
  }
  /** */
  public async getRecursosAsig(id_proyecto:number,callbackErr:any,callbackSuccess:any):Promise<void>{
    let recursos_asig:IGetRecursosAsigResponse;

    try{
      let request: IProyectRequest = this.httpService.getRequestProyect();
      recursos_asig = await  this.httpService.doPostHttp<IGetRecursosAsigResponse>("/getrecursos_asignados", request,{
        "id_proyecto": id_proyecto ?? null
      });
      this.recursos_asig = recursos_asig;
      if(recursos_asig.error){
        callbackErr(recursos_asig.error);
      }else{
        callbackSuccess();
      }
    }catch(e){
      callbackErr("Ha ocurrido un error inesperado");
    }
  }
  /**
   * 
   * @param username 
   * @param email 
   * @param password 
   */
 

  public updateProyectInMemory(proyects:ProyectFull[]){
    this.proyectsFull = proyects;
    localStorage.setItem("proyects",JSON.stringify(proyects));
  }

  public getHolidays(idProyecto:string):IFeriadosResponse {
    let proyects :  ProyectFull[] = this.getProyectsFromMemory();
    let holidays:IFeriadosResponse = proyects.find((proyect)=>proyect.proyect.id_proyecto.toString() === idProyecto).feriados;
    return holidays;
  };

  /**
   * 
   * @param idProyecto 
   * @param idFeriado 
   * @returns 
   */
  public deleteHoliday(idProyecto:string, idFeriado:string):boolean{
    try{
      let proyects :  ProyectFull[] = this.getProyectsFromMemory();
      let proyect:ProyectFull = proyects.find((proyect)=>proyect.proyect.id_proyecto.toString() === idProyecto);
      let holidays:IFeriadosResponse = proyect.feriados;
      let find = holidays.feriados.findIndex(holiday => holiday.id === idFeriado);
      holidays.feriados.splice(find,1);
      //this.updateProyectInMemory(proyects);
      return true;
    }catch(e){
      return false;
    }
  }


  /**
   * 
   * @param idProyecto 
   * @param idFeriado 
   * @returns 
   */
  public deleteScope(idProyecto:string, idScope:string):boolean{
    try{
      let proyects :  ProyectFull[] = this.getProyectsFromMemory();
      let proyect:ProyectFull = proyects.find((proyect)=>proyect.proyect.id_proyecto.toString() === idProyecto);
      let scopes:IAlcanceResponse = proyect.alcances;
      let find = scopes.alcances.findIndex(scope => scope.id === idScope);
      scopes.alcances.splice(find,1);
      this.updateProyectInMemory(proyects);
      return true;
    }catch(e){
      return false;
    }
  }



  /**
   * 
   * @param idProyecto 
   * @returns 
   */
  public async loadAlcances(idProyecto:number, callbackErr:any,callbackSuccess:any):Promise<void>{
    let alcance:IAlcanceResponse;

    try{
      let request: IAlcanceRequest = this.httpService.getAlcanceRequest();
      let body:IAlcanceRequest = { 
        "codigo_issues":"EPIC",
        "id_proyecto":idProyecto
      }
      alcance = await this.httpService.doPostHttp<IAlcanceResponse>("/getproyectotres",request,body);
      if(alcance.error){
        callbackErr(alcance.error);
      }else{
        callbackSuccess(alcance.alcances);
      }
    }catch(e){
      callbackErr("Ha ocurrido un error inesperado");
    }
  }

  /**
   * 
   * @param idProyecto 
   * @returns 
   */
  public async loadFeriados(idProyecto:number, callbackErr:any,callbackSuccess:any):Promise<void>{
    let feriado:IFeriadosResponse;

    try{
      let request: IFeriadosRequest = this.httpService.getFeriadosRequest();
      let body:IFeriadosRequest = { 
        "id_proyecto":idProyecto
      }
      feriado = await this.httpService.doPostHttp<IFeriadosResponse>("/getproyectocuatro",request,body);
      if(feriado.error){
        callbackErr(feriado.error);
      }else{
        callbackSuccess(feriado.feriados);
      }
    }catch(e){
      callbackErr("Ha ocurrido un error inesperado");
    }
  }
  /**
   * 
   * @param body 
   * @returns 
   */
  public async createProyect(body: ICreateProyectIRequest):Promise<ICreateProyectIResponse>{
    let request = this.httpService.getRequestCreateProyect(true);
    let createProyect: ICreateProyectIResponse = await  this.httpService.doPostHttp<ICreateProyectIResponse>("/createproyectoI", request,body);
    this.proyect = createProyect;
    let proyectFull: ProyectFull = {
      alcances:{
        alcances:[]
      },
      feriados:{
        feriados:[]
      },
      proyect:{
        "id_proyecto": createProyect.proyecto.id_proyecto,
        "id_cliente": createProyect.proyecto.id_cliente,
        "descripcion": createProyect.proyecto.descripcion,
        "presupuesto": createProyect.proyecto.presupuesto,
        "obj_general": createProyect.proyecto.obj_general,
        "id_moneda": createProyect.proyecto.id_moneda,
        "id_tipo_proyecto": createProyect.proyecto.id_tipo_proyecto,
        "fecha_inicio": createProyect.proyecto.fecha_inicio,
        "fecha_fin": createProyect.proyecto.fecha_fin,
        "moneda": this.staticDataService.currencies.find(currency => currency.id_moneda === createProyect.proyecto.id_moneda).descripcion,
        "tipo":  this.staticDataService.proyectTypeList.find(proyectType => proyectType.id_tipo_proyecto === createProyect.proyecto.id_tipo_proyecto).descripcion
      }
    }
    this.proyectsFull.push(proyectFull);
    this.updateProyectInMemory(this.proyectsFull);
    return createProyect;
  }
  /**
   * 
   * @param body 
   * @returns 
   */
  public async iniciarIte(body:any,callbackErr:any,callbackSuccess:any):Promise<void>{
    let iniciarIte:IIniciarIteResponse;
    try{
      let request = this.httpService.getRequestIniciarIte(true);
      iniciarIte = await  this.httpService.doPostHttp<IIniciarIteResponse>("/iniciar_iteracion", request,body);

      if(iniciarIte.error){
        callbackErr(iniciarIte.error);
      }else{
        callbackSuccess();
      }

    }catch(e){
      if(iniciarIte)
        callbackErr(iniciarIte.error);
      else
      callbackErr("Ha ocurrido un error inesperado");
    }
  }
  /** */
  public async crearCalendario(body:any,callbackErr:any,callbackSuccess:any):Promise<void>{
    let createcalendario:IAsignarRecursosResponse;
    try{
      let request = this.httpService.createCalendario(true);
      createcalendario = await  this.httpService.doPostHttp<IAsignarRecursosResponse>("/asignar_recurso_proyecto", request,body);

      if(createcalendario.error){
        callbackErr(createcalendario.error);
      }else{
        callbackSuccess(createcalendario);
      }

    }catch(e){
      if(createcalendario)
        callbackErr(createcalendario.error);
      else
      callbackErr("Ha ocurrido un error inesperado");
    }
  }
  /**
   * 
   */
  public async loadIteraciones(id_proyecto?: number):Promise<void>{
    let newIteracion:any;
    let body:IGetIteracionesRequest = { "id_proyecto":id_proyecto}
    let request: IGetIteracionesRequest = this.httpService.getRequestIteracionesList();
    this.iteracionList = await  this.httpService.doPostHttp<IGetIteracionesResponse>("/get_iteraciones", request,body);
    if(this.iteracionList.iteraciones != undefined){
      this.iteracionList.iteraciones = this.iteracionList.iteraciones.map((iteracion)=>{
        newIteracion = JSON.parse(JSON.stringify(iteracion));
        newIteracion.id_iteracion = newIteracion.id_iteracion.toString();
        return newIteracion;
      })
    }else{
      this.iteracionList.iteraciones = [];
        newIteracion = 0;
        return newIteracion;
    }
  }
    /**
   * 
   */
    public async loadFeriadosxAnio(body: any):Promise<void>{
      let request: IGetFeriadosAnioRequest = this.httpService.getRequestFeriadosList();
      this.feriadosxanio = await  this.httpService.doPostHttp<IGetFeriadosAnioResponse>("/getferiados", request,body);
      
    }
   /**
   * 
   */

  public async loadIssuesIteraciones(id_proyecto: number,id_iteracion: number,callbackErr:any,callbackSuccess:any):Promise<void>{
    let body:IGetIssuesIteRequest = { "id_proyecto":id_proyecto,
      "id_iteracion":id_iteracion}

    try{
      let request: IGetIssuesIteRequest = this.httpService.getRequestIssuesIteList();
      let issuesiteList = await  this.httpService.doPostHttp<IGetIssuesIteResponse>("/get_issues_iteraciones", request,body);
      this.issuesiteList = issuesiteList;
      if(issuesiteList.error){
        callbackErr(issuesiteList.error);
      }else{
        callbackSuccess();
      }
    }catch(e){
      callbackErr("Ha ocurrido un error inesperado");
    }
  }

   /**
   * 
   * @param body 
   * @returns 
   */
   public async editProyect(body: IEditProyectIRequest):Promise<IEditProyectIResponse>{
    let request = this.httpService.getRequestEditProyect(true);
    body.id_proyecto = this.proyect.proyecto.id_proyecto;
    let editProyect: IEditProyectIResponse = await  this.httpService.doPostHttp<IEditProyectIResponse>("/editarproyectoI", request,body);
    return editProyect;
  }

  /**
   * 
   * @param body 
   * @returns 
   */
  public async createProyectHolidays(body: ICreateProyectIVRequest):Promise<ICreateProyectIVResponse>{
    let request = this.httpService.getRequestCreateProyectHolidays(true);
    let createProyectHolidays: ICreateProyectIVResponse = await  this.httpService.doPostHttp<ICreateProyectIVResponse>("/createproyectocuatro", request,body);
    if(!createProyectHolidays.haveError){
      this.updateProyectInMemoryHolidays(true,createProyectHolidays);
    }
    return createProyectHolidays;
  }

  /**
   * 
   * @param body 
   * @returns 
   */
  public async editProyectHolidays(body: IEditProyectIVRequest):Promise<IEditProyectIVResponse>{
    let request = this.httpService.getRequestEditProyectHolidays(true);
    let editProyectHolidays: IEditProyectIVResponse = await  this.httpService.doPostHttp<IEditProyectIVResponse>("/editarproyectocuatro", request,body);
    if(!editProyectHolidays.haveError){
      this.updateProyectInMemoryHolidays(false, body); 
    }
    return editProyectHolidays;
  }


  /**
   * 
   * @param body 
   * @returns 
   */
  public async enviarData(data:any):Promise<void>{
    this.proyecto = data;
  }

  public async deleteProyectHolidays(body: IDeleteProyectIVRequest,callbackErr:any,callbackSuccess:any):Promise<void>{
    let deleteProyectHolidays: IDeleteProyectIVResponse;

    try{
      let request = this.httpService.getRequestDeleteProyectHolidays(true);
      deleteProyectHolidays = await  this.httpService.doPostHttp<IDeleteProyectIVResponse>("/deleteferiado", request,body);  
      if(deleteProyectHolidays.error){
        callbackErr(deleteProyectHolidays.error);
      }else{
        callbackSuccess(deleteProyectHolidays);
      }
    }catch(e){
      callbackErr("Ha ocurrido un error inesperado");
    }
  }


  /**
   * 
   * @param body 
   * @returns 
   */
  public async deleteProyectScope(body: IDeleteProyectIIIRequest):Promise<IDeleteProyectIIIResponse>{
    let request = this.httpService.getRequestDeleteProyectHolidays(true);
    let deleteProyectScope: IDeleteProyectIIIResponse = await  this.httpService.doPostHttp<IDeleteProyectIIIResponse>("/inhabilitar_issues", request,body);
    return deleteProyectScope;
  }


   /**
   * 
   * @param body 
   * @returns 
   */
   public async deleteFeriadoPais(body:any,callbackErr:any,callbackSuccess:any):Promise<void>{
    let feriado:IDeleteFeriadoPaisResponse;

    try{
      let request: IDeleteFeriadoPaisRequest= this.httpService.getRequestDeleteFeriadoPais();
      feriado = await  this.httpService.doPostHttp<IDeleteFeriadoPaisResponse>("/deleteferiado_pais", request,body);

      if(feriado.error){
        callbackErr(feriado.error);
      }else{
        callbackSuccess();
      }

    }catch(e){
      if(feriado)
        callbackErr(feriado.error);
      else
      callbackErr("Ha ocurrido un error inesperado");
    }
  }
  /** */
  public async cerrarProyecto(callbackErr:any,callbackSuccess:any):Promise<void>{
    let proyecto:ICerrarProyectoResponse;

    try{
      let request: ICerrarProyectoRequest= this.httpService.cerrarProyecto();
      let body = {
        "id_proyecto":this.proyecto
      }
      proyecto = await  this.httpService.doPostHttp<ICerrarProyectoResponse>("/inhabilitar_proyecto", request,body);

      if(proyecto.error){
        callbackErr(proyecto.error);
      }else{
        callbackSuccess();
      }

    }catch(e){
      if(proyecto)
        callbackErr(proyecto.error);
      else
      callbackErr("Ha ocurrido un error inesperado");
    }
  }
   /**
   * 
   * @param body 
   * @returns 
   */
   public async deleteRecursoAsig(body:any,callbackErr:any,callbackSuccess:any):Promise<void>{
    let recurso:IDeleteRecurAsigResponse;

    try{
      let request: IDeleteRecurAsigResponse= this.httpService.deleteRequestRecurAsig();
      recurso = await  this.httpService.doPostHttp<IDeleteRecurAsigResponse>("/deleterecursos_asig", request,body);

      if(recurso.error){
        callbackErr(recurso.error);
      }else{
        callbackSuccess();
      }

    }catch(e){
      if(recurso)
        callbackErr(recurso.error);
      else
      callbackErr("Ha ocurrido un error inesperado");
    }
  }

   /**
    * 
    * @param body 
    * @returns 
    */
   public async createProyectEpic(body: ICreateProyectIIIRequest):Promise<ICreateProyectIIIResponse>{
    let request = this.httpService.getRequestCreateProyectEpic(true);
    request.codigo_issues = "EPIC";
    let createEpic: ICreateProyectIIIResponse = await  this.httpService.doPostHttp<ICreateProyectIIIResponse>("/createproyectotres", request,body);
    if(!createEpic.haveError){
      this.updateProyectInMemoryScope(true, createEpic); 
    }
    return createEpic;
  }

    /**
   * 
   * @param body 
   * @returns 
   */
    public async editProyectScope(body: IEditProyectIIIRequest):Promise<IEditProyectIIIResponse>{
      let request = this.httpService.getRequestEditProyectScope(true);
      let editProyectScope: IEditProyectIIIResponse = await  this.httpService.doPostHttp<IEditProyectIIIResponse>("/editarproyectotres", request,body);
      if(!editProyectScope.haveError){
        this.updateProyectInMemoryScope(false, body); 
      }
      return editProyectScope;
    }


  /**
   * 
   */
  goToCreateProyects():void{
    this.nav.navigate(["/pages/site/create-proyect"]).then(()=>null);
  }

 /**
  * 
  */
  goToProyects():void{
    this.nav.navigate(["/pages/site/view-proyect"]).then(()=>null);
  }
  /**
   * 
   */
  goToDashborad():void{
    this.nav.navigate(["/pages/site/dashboard"]).then(()=>null);
  }

  goToCronograma():void{
    this.nav.navigate(["/pages/site/crear-cronograma"]).then(()=>null);
  }

  /**
   * 
   * @param status 
   * @returns 
   */
  public getStatusEpicName (status:string | number):string{ 
    return (Number(status) === 1) ? Lang.getI18N("i18n.statusEpic.active") : Lang.getI18N("i18n.statusEpic.inactive");
  }
  /**
   * 
   */
  public async saveIteracion(id_proyecto:number,id_cronograma:number,descripcion:string,fecha_inicio:string,fecha_fin:string,callbackErr:any,callbackSuccess:any):Promise<void>{
    let ite:IIteracionResponse;

    try{
      let request: IIteracionRequest = this.httpService.getRequestIteraciones();
      ite = await  this.httpService.doPostHttp<IIteracionResponse>("/create_iteracion", request, {
        "id_proyecto":id_proyecto,
        "id_cronograma":id_cronograma,
        "descripcion":descripcion,
        "fecha_inicio":fecha_inicio,
        "fecha_fin":fecha_fin
      });

      if(ite.error){
        callbackErr(ite.error);
      }else{
        callbackSuccess();
      }

    }catch(e){
      if(ite)
        callbackErr(ite.error);
      else
      callbackErr("Ha ocurrido un error inesperado");
    }
  }
    /**
   * 
   */
    public async MoverIssuesIteracion(asignacion:any,eliminacion:any,callbackErr:any,callbackSuccess:any):Promise<void>{
      let mover:IMoverissuesResponse;
  
      try{
        let request: IMoverIssuesRequest = this.httpService.getRequestMover();
        let body: IMoverIssuesRequest = {"asignacion":asignacion,
                                         "eliminacion":eliminacion};
        mover = await  this.httpService.doPostHttp<IMoverissuesResponse>("/mover_issues_iteraciones", request,body);
  
        if(mover.error){
          callbackErr(mover.error);
        }else{
          callbackSuccess();
        }
  
      }catch(e){
        if(mover)
          callbackErr(mover.error);
        else
        callbackErr("Ha ocurrido un error inesperado");
      }
    }
     /**
 * 
 */
     public async CrearFeriadoAnio(body:any,callbackErr:any,callbackSuccess:any):Promise<void>{
      let feriado:ICreateFeriadosAnioResponse;
  
      try{
        let request: ICreateFeriadosAnioRequest= this.httpService.getRequestFeriadoCreate();
        feriado = await  this.httpService.doPostHttp<ICreateFeriadosAnioResponse>("/createferiados", request,body);
  
        if(feriado.error){
          callbackErr(feriado.error);
        }else{
          callbackSuccess();
        }
  
      }catch(e){
        if(feriado)
          callbackErr(feriado.error);
        else
        callbackErr("Ha ocurrido un error inesperado");
      }
    }

    /**
     * 
     * @returns 
     */
    public getProyectsFromMemory(): ProyectFull[]{
     return (JSON.parse(localStorage.getItem("proyects")) as ProyectFull[]);
    }

    public updateProyectInMemoryHolidays(isNew:boolean = false, holiday:ICreateProyectIVResponse | IEditProyectIVRequest){
       try{
        let proyects :  ProyectFull[] = this.getProyectsFromMemory();
        let proyectFull: ProyectFull;
        if(isNew) {
           proyectFull = proyects.find((proyect)=>proyect.proyect.id_proyecto.toString() === (holiday as ICreateProyectIVResponse).feriados.id_proyecto.toString());
          let newHoliday:IFeriadosResponseProyect = {
           id:(holiday as ICreateProyectIVResponse).feriados.id,
           id_proyecto:(holiday as ICreateProyectIVResponse).feriados.id_proyecto,
           id_pais:(holiday as ICreateProyectIVResponse).feriados.id_pais,
           year:(holiday as ICreateProyectIVResponse).feriados.year,
           p_descripcion:this.staticDataService.findCountryById((holiday as ICreateProyectIVResponse).feriados.id_pais.toString()).nombre
          };
          proyectFull.feriados.feriados.push(newHoliday)
        }else{
           proyectFull = proyects.find((proyect)=>proyect.proyect.id_proyecto.toString() === this.proyect.proyecto.id_proyecto.toString());
           proyectFull.feriados.feriados.forEach((h)=>{
              if(h.id.toString() === (holiday as IEditProyectIVRequest).id.toString()){
                h.id_pais = (holiday as IEditProyectIVRequest).id_pais;
                h.year = (holiday as IEditProyectIVRequest).year;
                h.p_descripcion = this.staticDataService.findCountryById((holiday as IEditProyectIVRequest).id_pais.toString()).nombre;


              }
           });
        }
        this.updateProyectInMemory(proyects);
       }catch(err){
          throw err;
       }
    }


    public updateProyectInMemoryScope(isNew:boolean = false, epicData:ICreateProyectIIIResponse | IEditProyectIIIRequest){
      try{
       let proyects :  ProyectFull[] = this.getProyectsFromMemory();
       let proyectFull: ProyectFull;
       if(isNew) {
          proyectFull = proyects.find((proyect)=>proyect.proyect.id_proyecto.toString() === (epicData as ICreateProyectIIIResponse).alcance.id_proyecto.toString());
          let epic : IAlcanceResponseProyect = {
            "id": (epicData as ICreateProyectIIIResponse).alcance.id,
            "id_proyecto" : (epicData as ICreateProyectIIIResponse).alcance.id_proyecto,
            "titulo": (epicData as ICreateProyectIIIResponse).alcance.titulo,
            "descripcion":(epicData as ICreateProyectIIIResponse).alcance.descripcion,
            "estatus": this.getStatusEpicName((epicData as ICreateProyectIIIResponse).alcance.activo),
            "id_estado":(epicData as ICreateProyectIIIResponse).alcance.id_estado
          }
          proyectFull.alcances.alcances.push(epic);
          this.updateProyectInMemory(proyects);
       }else{
          proyectFull = proyects.find((proyect)=>proyect.proyect.id_proyecto.toString() === this.proyect.proyecto.id_proyecto.toString());
          proyectFull.alcances.alcances.forEach((a)=>{
             if(a.id.toString() === (epicData as IEditProyectIIIRequest).id.toString()){
               a.titulo = (epicData as IEditProyectIIIRequest).nombre;
               a.descripcion = (epicData as IEditProyectIIIRequest).descripcion;
             }
          });
       }
       this.updateProyectInMemory(proyects);
      }catch(err){
         throw err;
      }
   }
}


