import { IResponseBase } from "../../services/structure-http-interface/base/IResponseBase";

/**
 * 
 */
export abstract class AbstractBiz{
    private _decorator: IResponseBase;
    /**
     * 
     */
    public get decorator():IResponseBase{
        return this._decorator;
    }
    /**
     * 
     */
    public set decorator(value:IResponseBase){
        this._decorator = value;
    }
}